import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'




const HowOur = () => {



  return (
    <div className='pt-100'>
    <section className="how-our mb-0">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h3 className="heading-h2"><span className="heading-h3"><span className='bluecolor'>How Does</span> Our </span> White Label Cryptocurrency Exchange Software Work?
          </h3>
        </div>
        <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/whitelabel-new/workflow.png"
              alt="Workflow of white label crypto exchange software"
              width={1110}
            />
      </div>
    </section>
    </div>
  )
}

export default HowOur