import React, { Component } from 'react'
import { StaticImage } from 'gatsby-plugin-image';


class CoreFeatures extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }


  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2">
              <span className="heading-h3">
                <span className="bluecolor">Core Features</span> of our
              </span>
              White Label Cryptocurrency Exchange Software
            </h3>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul
                className="nav nav-tabs tabNav nav-pills"
                id="myTab"
                role="presentation"
              >
                <li
                  role="presentation"
                  onKeyDown={this.openTabSection}
                  onClick={e => this.openTabSection(e, "tab7")}
                >
                  Support System
                </li>
                <li
                  role="presentation"
                  onKeyDown={this.openTabSection}
                  onClick={e => this.openTabSection(e, "tab8")}
                >
                  Admin Profit Management
                </li>
                <li
                  role="presentation"
                  onKeyDown={this.openTabSection}
                  onClick={e => this.openTabSection(e, "tab12")}
                >
                  Margin Trading
                </li>
                <li
                  role="presentation"
                  onKeyDown={this.openTabSection}
                  onClick={e => this.openTabSection(e, "tab13")}
                >
                  Futures Trading
                </li>
                <li
                  role="presentation"
                  onKeyDown={this.openTabSection}
                  onClick={e => this.openTabSection(e, "tab14")}
                >
                  IEO Launchpad
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6  order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Support System</h4>
                        <p className="pharagraph">
                        Instant support system that helps your users to raise queries and also a chat box for them to directly chat with the admin.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6  right-side order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/whitelabel-new/support-system.png"
                      alt="Support System"
                      width={390}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6  order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Admin Profit Management</h4>
                        <p className="pharagraph">
                        Our White-Label Crypto exchange solution comes with a transparent profit management system for Admin where the commissions would be calculated and displayed.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6  right-side order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/whitelabel-new/admin-profit-management.png"
                      alt="Admin Profit Management"
                      width={420}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab12" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6  order2">
                      <div className="mw466">
                        <h4 className="heading-h4"> Margin Trading</h4>
                        <p className="pharagraph">
                        Your users can use borrowed funds from a third party to lever up a cryptocurrency trade. Besides, they can open a larger position with fewer funds by using margin trading.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6  right-side order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/whitelabel-new/margin-trading.png"
                      alt="Margin Trading"
                      width={240}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab13" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6  order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Futures Trading</h4>
                        <p className="pharagraph">
                        The Advanced and key feature in our software ensures users can buy/sell assets at a predetermined price on a specific date or without any expiry date. 
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6  right-side order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/whitelabel-new/futures-trading.png"
                      alt="Futures Trading"
                      width={540}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab14" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6  order2">
                      <div className="mw466">
                        <h4 className="heading-h4">IEO Launchpad</h4>
                        <p className="pharagraph">
                        IEO is the simplest and easiest way to raise funds for cryptocurrency projects. We at Coinsclone integrate a secure IEO launchpad for token sales.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 right-side order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/whitelabel-new/ieo-launchpad.png"
                      alt="IEO Launchpad"
                      width={295}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default CoreFeatures