import React from 'react'



class Whatis extends React.Component {


  render() {

    return (  
      <section className="whatis mb-0 py-0">
        <div className="gray-bg">
          <div className="container">
                <h2 className="heading-h2 text-center"><span className='bluecolor heading-h3'>What is</span> White Label Cryptocurrency Exchange Software?</h2>
            <div className="text-center">
              <p className="pharagraph text-center">White Label Cryptocurrency Exchange software is a turnkey solution for startups looking to launch their own customized cryptocurrency exchange platform under their own branding. It is cost-effective, time-saving, and designed in a way to meet specific requirements. Our bug-free White Label Crypto exchange software supports all the major virtual cryptocurrency coins and tokens and enables your users to trade a wide range of cryptocurrency assets as per their wishes.
              </p>
              <p className="pharagraph text-center mb-0">Coinsclone’s pre-built cryptocurrency exchange software is highly responsive, secure, and error-free, and has several customizable options for startups. You can customize the theme, trading features, design, color, name, logo, and other add-ons as per your business concepts without any trouble. Additionally, our White Label crypto exchange software development comes with a user-friendly dashboard and works without any glitches which helps to provide the smooth & best trading experience for your users.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Whatis