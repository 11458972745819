import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class BannerSection extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="banner">
        <div className="container text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h1 className="main-heading">Best White Label Cryptocurrency Exchange Software</h1>
            <p className="sub-heading">Initiate your own cryptocurrency business instantly with our best White Label Cryptocurrency Exchange Software</p>
            <p className="sub-small">Develop an ultimate cryptocurrency exchange with versatile features using our leading White Label services.</p>
          </div>
          <div className="quick text-center">
             <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/whitelabel/white-label-cryptocurrency-exchange.webp"
              alt="Whitelabel Crypto Exchange  Development Company Banner"
              width={980}
            />   
          </div>
          <p className="pharagraph">Talk to our experts and grab a free demo of our White Label Crypto exchange software</p>
          <div className="cta-banner justify-content-md-center">
              <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection