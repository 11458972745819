import React from 'react'


const WhyChoose = () => {


  return (
    <section className="choosedesire ychoose pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h4 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Why Choose </span>Coinsclone for</span>
            White Label Cryptocurrency Exchange Software</h4>
            <p className="pharagraph head">By joining hands with Coinsclone, you can get bug-free and feature-packed White-Label services.
            </p>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className='lazyload'  width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/whitelabel/award-winning-cryptocurrency-exchange-software-provider.svg" alt="Award-winning cryptocurrency exchange software  provider" />
                </div>
              </div>
              <div className="content-rght">
                <p className='listing'>
                Award-winning White Label Cryptocurrency Exchange Provider
                </p>
                <p className="pharagraph">
                Being a pioneer in the crypto field, we are masters in crafting outstanding White Label Cryptocurrency Exchanges with impeccable outcomes.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3">
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className='lazyload' width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/whitelabel/one-stop-solution-for-all-crypto-exchange-services.svg" alt="One-stop solution for all crypto exchange services" />
                </div>
              </div>
              <div className="content-rght">
              <p className='listing'>
                One-stop Solution for all Cryptocurrency Exchange Services
                </p>
                <p className="pharagraph">
                We at Coinsclone offer end-to-end cryptocurrency exchange software solutions for all budding startups and entrepreneurs willing to start a cryptocurrency exchange business & reap profits.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className='lazyload'  width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/whitelabel/delivered-more-than-200+-projects.svg" alt="Delivered more than 200+ projects" />
                </div>
              </div>
              <div className="content-rght">
              <p className='listing'>
                Delivered More than 200+ Projects
                </p>
                <p className="pharagraph">
                As the leading White-Label Cryptocurrency Exchange provider in the cryptocurrency industry, so far we have successfully delivered more than 200+ projects to our international clients.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className='lazyload'  width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/whitelabel/on-time-product-delivery.svg" alt="On-time product delivery" />
                </div>
              </div>
              <div className="content-rght">
              <p className='listing'>
                On-time Product Delivery
                </p>
                <p className="pharagraph">
                Our developers will customize the White-Label platform according to the client’s requirements and deliver them to the client within the given time frame.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex lastblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className='lazyload'  width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/whitelabel/a-dedicated-team-of-experts.svg" alt="A dedicated team of experts" />
                </div>
              </div>
              <div className="content-rght">
              <p className='listing'>
                A Dedicated Team of Experts
                </p>
                <p className="pharagraph">
                We have a team of skilled and professional developers handling all sorts of White Label Cryptocurrency Exchange development projects.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className='lazyload'  width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/whitelabel/24-7-customer-and-technical-support.svg" alt="24-7 customer and technical support" />
                </div>
              </div>
              <div className="content-rght">
              <p className='listing'>
                24/7 Customer and Technical Support
                </p>
                <p className="pharagraph">
                Coinsclone offers productive technical and customer support through the internet on a 24/7 basis for all our services/products.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChoose